import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import AuthGuard from 'src/guards/authGuard/AuthGuard';
import GuestGuard from 'src/guards/authGuard/GuestGaurd';


/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

/* ****Pages***** */
const DashboardPage = Loadable(lazy(() => import('src/components/pages/dashboard/Dashboard')));

const MyCoursesPage = Loadable(lazy(() => import('src/components/pages/courses/MyCourses')));
const MyKnowledgePage = Loadable(lazy(() => import('src/components/pages/myknowledge/MyKnowledgePage')));
const TutorPage = Loadable(lazy(() => import('src/components/pages/tutor/TutorPage')));
const CommunityPage = Loadable(lazy(() => import('src/components/pages/community/CommunityPage')));

const CourseInfo = Loadable(lazy(() => import('src/components/pages/courses/CourseInfo')));
const AddCoursesPage = Loadable(lazy(() => import('src/components/pages/courses/AddCourse')));
const CourseOverview = Loadable(lazy(() => import('src/components/pages/courses/CourseOverview')));
const AssignmentsPage = Loadable(lazy(() => import('src/components/pages/assignments/AssignmentPage')));
const UserProfilePage = Loadable(lazy(() => import('src/components/pages/userprofile/UserProfilePage')));

const LessonsPageNew = Loadable(lazy(() => import('src/components/pages/lessonnew/LessonLayout')));
const EvaluationTestPage = Loadable(lazy(() => import('src/components/pages/lessonnew/lessonmain/test/EvaluationTest')));

const CodeActivityPage = Loadable(lazy(() => import('src/components/pages/code/CodeWrapper')));

const LearnPage = Loadable(lazy(() => import('src/components/pages/learn/LearnLayout')));


const Login = Loadable(lazy(() => import('../views/authentication/auth1/Login')));
const Login2 = Loadable(lazy(() => import('../views/authentication/auth2/Login2')));
const Register = Loadable(lazy(() => import('../views/authentication/auth1/Register')));
const Register2 = Loadable(lazy(() => import('../views/authentication/auth2/Register2')));
const Error = Loadable(lazy(() => import('../views/authentication/Error')));




const Router = [
  {
    path: '/',
    element: (
      //  <AuthGuard>
        <FullLayout />
      // </AuthGuard>
    ),
    children: [
      { path: '/', element: <Navigate to="/dashboard" /> },
      { path: '/dashboard', element: <DashboardPage /> },
      { path: '/mycourse', element: <MyCoursesPage /> },
      { path: '/myknowledge', element: <MyKnowledgePage /> },
      { path: '/tutor', element: <TutorPage /> },
      { path: '/assignments', element: <AssignmentsPage />},
      { path: '/community', element: <CommunityPage /> },
      { path: '/addcourse', element: <AddCoursesPage /> },
      { path: '/courseinfo', element: <CourseInfo /> },
      { path: '/courseoverview', element: <CourseOverview /> },
      { path: '/user-profile', element: <UserProfilePage />},
      { path: '/learn', element: <LearnPage /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: '/evaluationtest', element: <EvaluationTestPage /> },
      { path: '/lessons', element: <LessonsPageNew /> },
      { path: '/codeactivity', element: <CodeActivityPage /> },
    ],
  },
  {
    path: '/auth',
    element: (
      <GuestGuard>
        <BlankLayout />
      </GuestGuard>
    ),
    children: [
      { path: '/auth/login2', element: <Login2 /> },
      { path: '/auth/login', element: <Login2 /> },
      { path: '/auth/register2', element: <Register /> },
      { path: '/auth/register', element: <Register2 /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];


export default Router;
