import { FC } from 'react';
import { CssBaseline, ThemeProvider, GlobalStyles } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'src/store/Store';
import { ThemeSettings } from './theme/Theme';
import RTL from './layouts/full/shared/customizer/RTL';
import ScrollToTop from './components/shared/ScrollToTop';
import { AppState } from './store/Store';
import { WhitelabelProvider } from './whitelabel/WhitelabelContext';
import Router from './routes/Router';

const App: FC = () => {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const customizer = useSelector((state: AppState) => state.customizer);

  // Assume you fetch company info from the DB
  const fetchedCompanyName = "default";
  // Then, you'd use the context's setCompanyName method to update it.

  return (
    <WhitelabelProvider> {/* You've wrapped the app's contents with WhitelabelProvider */}
      <ThemeProvider theme={theme}>
        <RTL direction={customizer.activeDir}>
          <CssBaseline />

          <GlobalStyles styles={{
            '*': {
              scrollbarWidth: 'none', // Hide scrollbar in Firefox
              '-ms-overflow-style': 'none', // Hide scrollbar in Internet Explorer
              '&::-webkit-scrollbar': {
                width: '0.4em', // Customize the width of the scrollbar
                display: 'none', // Hide scrollbar in WebKit-based browsers
              },
            }
          }} />

          <ScrollToTop>{routing}</ScrollToTop>
        </RTL>
      </ThemeProvider>
    </WhitelabelProvider>
  );
};

export default App;
