import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';

const firebaseConfig = {
  apiKey: "AIzaSyBGMHCl1NW0RuvhqBy271ohrIZFCeLy1M8",
  authDomain: "playdemy-62571.firebaseapp.com",
  databaseURL: "https://playdemy-62571-default-rtdb.firebaseio.com",
  projectId: "playdemy-62571",
  storageBucket: "playdemy-62571.appspot.com",
  messagingSenderId: "683674379709",
  appId: "1:683674379709:web:474bbc5e016313ddba9ed4"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const Db = firebase.database();
const Auth = firebase.auth();

export { Db, Auth, firebase };
