import React, { createContext, useContext, useState, ReactNode, FC } from 'react';

interface WhiteLabelContextType {
  companyName: string;
  setCompanyName: (name: string) => void;
  logoName: string;  // Added logoName property
  setLogoName: (name: string) => void;  // Added setter for logoName
}

const WhiteLabelContext = createContext<WhiteLabelContextType | undefined>(undefined);

export const useWhiteLabel = () => {
  const context = useContext(WhiteLabelContext);
  if (!context) {
    throw new Error('useWhiteLabel must be used within a WhitelabelProvider');
  }
  return context;
};

interface WhitelabelProviderProps {
  children: ReactNode;
}

export const WhitelabelProvider: FC<WhitelabelProviderProps> = ({ children }) => {
  const [companyName, setCompanyName] = useState<string>('default');
  const [logoName, setLogoName] = useState<string>('logo');  // You can set the default logo name here

  return (
    <WhiteLabelContext.Provider value={{ companyName, setCompanyName, logoName, setLogoName }}>
      {children}
    </WhiteLabelContext.Provider>
  );
};
