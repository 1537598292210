import { createContext, useEffect, useReducer } from 'react';
import { firebase } from './Firebase';

export interface InitialStateType {
  isAuthenticated?: boolean;
  isInitialized?: boolean;
  user?: any | null | undefined;
}

const initialState: InitialStateType = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state: InitialStateType, action: any) => {
  if (action.type === 'AUTH_STATE_CHANGED') {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

interface AuthContextType {
  isAuthenticated?: boolean;
  isInitialized?: boolean;
  user?: any;
  platform: string;
  signup: (email: string, password: string) => Promise<any>;
  signin: (email: string, password: string) => Promise<any>;
  logout: () => Promise<void>;
  getToken: () => Promise<string | undefined>; // Ensure this is included
  CreateUser: (id: string, username: string, email: string) => Promise<void>;
  onceGetUsers: () => Promise<any>;
  loginWithGoogle: () => Promise<any>;
  loginWithFaceBook: () => Promise<any>;
  loginWithTwitter: () => Promise<any>;
  // ... other properties or methods
}

const AuthContext = createContext<AuthContextType | null>({
  ...initialState,
  platform: 'Firebase',
  signup: () => Promise.resolve(),
  signin: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  onceGetUsers: () => Promise.resolve(),
  CreateUser: () => Promise.resolve(),
  getToken: () => {
    // Return a promise that resolves to a string (token) or undefined
    return new Promise<string | undefined>((resolve, reject) => {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        currentUser.getIdToken().then(resolve).catch(reject);
      } else {
        resolve(undefined);
      }
    });
  },
  // Since these functions are required by AuthContextType, they must be defined,
  // even if they are just placeholders.
  loginWithGoogle: () => Promise.resolve(),
  loginWithFaceBook: () => Promise.resolve(),
  loginWithTwitter: () => Promise.resolve(),
  // Add any additional required functions or properties here
});



export const AuthProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(reducer, initialState);



  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          // Here you should extract the complete user profile to make it available in your entire app.
          // The auth state only provides basic information.
          // console.log('****************');
          // console.log('~~USER~~');
          // console.log(user);
          // console.log('****************');
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: true,
              user: {
                id: user.uid,
                avatar: user.photoURL,
                email: user.email,
              },
            },
          });
        } else {
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      }),
    [dispatch],
  );

  // Login with FB

  const loginWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };

  const getToken = () => {
    return firebase.auth().currentUser?.getIdToken();
  };

  // Login with FB
  const loginWithFaceBook = () => {
    const provider = new firebase.auth.FacebookAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };

  const loginWithTwitter = () => {
    const provider = new firebase.auth.TwitterAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };

  // Sign Up
  const signup = (email: string, password: string) =>
    firebase.auth().createUserWithEmailAndPassword(email, password);

  // Sign In
  const signin = (email: string, password: string) =>
    firebase.auth().signInWithEmailAndPassword(email, password);

  // Sign out
  const logout = () => firebase.auth().signOut();
  const CreateUser = (id: string, username: string, email: string) =>
    firebase.database().ref(`users/${id}`).set({
      username,
      email,
    });
  const onceGetUsers = () => firebase.database().ref('users').once('value');

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Firebase',
        signup,
        getToken,
        signin,
        CreateUser,
        onceGetUsers,
        loginWithGoogle,
        loginWithFaceBook,
        loginWithTwitter,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
